
import bg2 from '../imgs/bg2webP.webp'
import elec from '../imgs/electric.jpg'
import elecwebP from '../imgs/electric.webp'
import park from '../imgs/park.jpg'
import parkwebP from '../imgs/park.webp'
import util from '../imgs/util.jpg'
import utilwebP from '../imgs/util.webp'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

const About = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])

    return (
        <div id="about" className="main" style= {{ backgroundImage: `url(${ bg2 })`}} >
            <motion.div className="content"
                            initial={ {opacity: 0 } }
                            animate={ {opacity: 1} }
                            transition={{
                                ease: "easeInOut",
                                duration: 0.2
                            }}
                            exit={ {
                                opacity: 0, 
                                filter: "blur(20px)"
                                } }
                >
                

                <h1> RV Sites </h1>
                <picture>
                    <source srcSet= { parkwebP } type ="image/webp" />
                    <img src= { park } alt="decorative" />
                </picture>

                <br />

                <ul>
                    <li>
                        We currently have 7 RV unit sites
                    </li>
                    <li>
                        Each site has:
                        <ul>
                            <li>
                            Water and sewer hookups
                            </li>
                            <li>
                                50/30/20 amp electrical service
                            </li>
                            <li>
                                Each site is 15' x 58'
                            </li>
                            <li>
                                Gravel roads and driveways to make parking easy and convenient
                            </li>
                        </ul> 
                    </li>
                </ul>

                    <br />
                
                <picture >
                    <source srcSet= { utilwebP } type ="image/webp" className='infopic' />
                    <img src= { util } alt="decorative" className='infopic' />
                </picture>

                <br />

                <picture >
                    <source srcSet= { elecwebP } type ="image/webp" className='infopic' />
                    <img src= { elec } alt="decorative" className='infopic' />
                </picture>

                <br />

                    <h2>Short-term Pricing</h2>
                    Daily Rates
                    <ul>
                        <li>
                            30 amp:
                        </li>
                        <ul>
                            <li>
                                Electric Only: $30 (water source & sewer dump availale to use onsite)
                            </li>
                            <li>
                                Electric, Water, and Sewer hookups: $35
                            </li>
                        </ul>
                        <li>
                            50 amp:
                        </li>
                        <ul>
                            <li>
                            Electric Hookup: $40  (water source & sewer dump availale to use onsite)
                            </li>
                            <li>
                                Electric, Water, and Sewer hookups: $45
                            </li>
                        </ul>
                    </ul>

<h2>Longer-Term Pricing</h2>
                    Weekly & Monthly rates include all hookups (water, electric, sewer) and trash disposal.
                    <br/>
                    <br/>
                    Weekly Rates:
                    <ul>
                        <li>
                            30 amp: $138
                        </li>
                        <li>
                            50 amp: $150
                        </li>
                        <li>
                            Hi-speed fiber internet: $8
                        </li>
                    </ul>
                    <br/>
                            Monthly Rates:
                            <ul>
                            <li>
                                30 amp: $550
                            </li>
                            <li>
                                50 amp: $600
                            </li>
                            <li>
                                Hi-speed fiber internet: $30
                            </li>
                        </ul>

                    <h2>Park Amenities</h2>
                    <ul>
                        <li>
                        We offer lunch service with prepared meals you can take to work
                        </li>
                        <li>
                            On-site laundry service is offered until our laundry facility is built; Alternatively, there is a laundromat located 8 miles away in Pulaski
                        </li>
                        <li>
                            Nearby bar with food service within walking distance 
                        </li>
                        <li>
                            Hi-speed fiber internet available 
                        </li>
                    </ul>

                    
                    <br />
                    <br />

                    <h2>About Us</h2>
                    <span>
                        Our park is the ideal spot for traveling workers, thanks to its convenient location near the interstate and nearby bar within walking distance. The bar serves home-cooked meals and cold beers, and offers a friendly atmosphere for conversation. Enjoy sitting in the bar's large outdoor area as the sun sets, and relax at the bonfire with the stars at night. The bar also offers a range of activities and weekend music events to keep you entertained. For further information, visit <a href="https://nirvanaroadhouse.com/" target="_blank" rel="noreferrer">Nirvana Roadhouse.</a>
                    </span>

                    
            </motion.div>
        </div>
    )

}

export default About