import pic1 from '../imgs/home.jpg'
import picwebP from '../imgs/home.webp'
import bg2 from '../imgs/bg2webP.webp'
import { motion } from 'framer-motion'
import { useEffect } from 'react'


const Home = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])

    return (
        <div id="home" className="main" style= {{ backgroundImage: `url(${ bg2 })`}} >
            <motion.div className="content"
                            initial={ {opacity: 0 } }
                            animate={ {opacity: 1} }
                            transition={{
                                ease: "easeInOut",
                                duration: 0.2
                            }}
                            exit={ {
                                opacity: 0, 
                                filter: "blur(20px)"
                                } }
                                >
                <picture>
                    <source srcSet= { picwebP } type ="image/webp" />
                    <img src= { pic1 } alt="decorative" />
                </picture>
                
                <h1> Welcome to Nirvana RV park! </h1>
                <span>
                    We are a brand new facility located in beautiful south central Tennessee, just 3 miles west of exit 14 on I-65. The park is 1.5 miles down a quiet residential road, surrounded by pastures & woods.
                    Nirvana is a peaceful spot in the country. Our park does not allow children to reside on the premises. Additionally, outdoor pets are not permitted, with the exception of small dogs that may be taken for walks on a leash.
                </span>

                <br />
                <span>
                    Nirvana is the perfect spot for an easily accessible, quiet, country living through the work week and plenty of entertainment on the weekends. 
                    <br />
                    Come stay with us and find your own Nirvana!!
                </span>
            </motion.div>
        </div>
    )

}

export default Home